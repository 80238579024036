#holder-benefits-section {
  max-width: unset;
  margin: 256px auto;
  padding: 0;
}

#holder-benefits-section h1 {
  color: #35a4cd;
  background: linear-gradient(90deg, #1e201980 0%, #1e2019 50%, #1e201980 100%);
  margin: 0 auto 128px;
  padding: 16px 32px;
}

#holder-benefits-section h1 span {
  color: #e2efb3;
}

#holder-benefits-section #benefits-split {
  max-width: 1440px;
  align-items: flex-start;
  margin: 0 auto;
  display: flex;
}

#holder-benefits-section #benefits-split .benefits-split-side {
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#holder-benefits-section #benefits-split .benefits-split-side:first-child .benefits-icon-img {
  background-image: -webkit-image-set(url("lunchroom-key.b8439633.webp") 1x, url("lunchroom-key.8a9766b6.webp") 2x);
  background-image: image-set("lunchroom-key.b8439633.webp" 1x, "lunchroom-key.8a9766b6.webp" 2x);
}

#holder-benefits-section #benefits-split .benefits-split-side:last-child .benefits-icon-img {
  background-image: -webkit-image-set(url("future-vip.cd2ef183.webp") 1x, url("future-vip.ea2d1791.webp") 2x);
  background-image: image-set("future-vip.cd2ef183.webp" 1x, "future-vip.ea2d1791.webp" 2x);
}

#holder-benefits-section #benefits-split .benefits-split-side .benefits-icon-img {
  width: 360px;
  height: 360px;
  background-size: cover;
  border: 7px solid #e2efb3;
  border-radius: 360px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px #0000001a;
}

#holder-benefits-section #benefits-split .benefits-split-side .benefits-subtitle, #holder-benefits-section #benefits-split .benefits-split-side .benefits-desc {
  color: #e2efb3;
  text-align: center;
}

#holder-benefits-section #benefits-split .benefits-split-side .benefits-subtitle {
  margin: 64px auto 48px;
  font-family: Permanent Marker, cursive;
  font-size: 64px;
}

#holder-benefits-section #benefits-split .benefits-split-side .benefits-subtitle span {
  color: #35a4cd;
}

#holder-benefits-section #benefits-split .benefits-split-side .benefits-desc {
  max-width: 582px;
  margin: 0 auto;
  font-family: Varela Round, sans-serif;
  font-size: 20px;
  line-height: 1.21;
}

@media (max-width: 1360px) {
  #holder-benefits-section #benefits-split {
    display: block;
  }

  #holder-benefits-section #benefits-split .benefits-split-side {
    width: 100%;
  }

  #holder-benefits-section #benefits-split .benefits-split-side:last-child {
    margin-top: 96px;
  }
}

@media (max-width: 900px) {
  #holder-benefits-section {
    margin: 128px auto;
  }

  #holder-benefits-section h1 {
    margin-bottom: 64px;
  }

  #holder-benefits-section #benefits-split .benefits-split-side:last-child {
    margin-top: 64px;
  }

  #holder-benefits-section #benefits-split .benefits-split-side .benefits-subtitle, #holder-benefits-section #benefits-split .benefits-split-side .benefits-desc {
    padding: 0 16px;
  }

  #holder-benefits-section #benefits-split .benefits-split-side .benefits-subtitle {
    margin: 48px auto 32px;
    font-size: 48px;
  }

  #holder-benefits-section #benefits-split .benefits-split-side .benefits-desc {
    font-size: 18px;
  }
}

/*# sourceMappingURL=index.7b7f24e7.css.map */
