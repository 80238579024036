@import "/src/constants";

#holder-benefits-section {
  margin: 256px auto;
  max-width: unset;
  padding: 0;

  h1 {
    margin: 0 auto 128px;
    padding: 16px 32px;
    background: titleBg($dark-chocolate);
    color: $blue;

    span {
      color: $beige;
    }
  }

  #benefits-split {
    display: flex;
    align-items: flex-start;
    max-width: 1440px;
    margin: 0 auto;

    .benefits-split-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      &:first-child .benefits-icon-img {
        background-image: image-set(
          url("/src/assets/images/holder-benefits/lunchroom-key.webp?as=webp&width=346")
            1x,
          url("/src/assets/images/holder-benefits/lunchroom-key.webp?as=webp&width=692")
            2x
        );
      }

      &:last-child .benefits-icon-img {
        background-image: image-set(
          url("/src/assets/images/holder-benefits/future-vip.webp?as=webp&width=346")
            1x,
          url("/src/assets/images/holder-benefits/future-vip.webp?as=webp&width=692")
            2x
        );
      }

      .benefits-icon-img {
        width: 360px;
        height: 360px;
        border-radius: 360px;
        overflow: hidden;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
        background-size: cover;
        border: 7px solid $beige;
      }

      .benefits-subtitle,
      .benefits-desc {
        color: $beige;
        text-align: center;
      }

      .benefits-subtitle {
        font-family: "Permanent Marker", cursive;
        font-size: 64px;
        margin: 64px auto 48px;

        span {
          color: $blue;
        }
      }

      .benefits-desc {
        font-family: "Varela Round", sans-serif;
        font-size: 20px;
        line-height: 1.21;
        max-width: 582px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 1360px) {
  #holder-benefits-section {
    #benefits-split {
      display: block;

      .benefits-split-side {
        width: 100%;

        &:last-child {
          margin-top: 96px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  #holder-benefits-section {
    margin: 128px auto;

    h1 {
      margin-bottom: 64px;
    }

    #benefits-split {
      .benefits-split-side {
        &:last-child {
          margin-top: 64px;
        }

        .benefits-subtitle,
        .benefits-desc {
          padding: 0 16px;
        }

        .benefits-subtitle {
          font-size: 48px;
          margin: 48px auto 32px;
        }

        .benefits-desc {
          font-size: 18px;
        }
      }
    }
  }
}
